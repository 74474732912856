import '../styles/app.css';

import { createApp } from 'vue'

import Header from "@/components/Site/Header/Header.vue";
import Button from "@SpongeFoundation/vue/Shop/Button/Button.vue";
import FlashMessage from "@SpongeFoundation/vue/Shop/FlashMessage/FlashMessage.vue";
import Consent from "../components/Site/Consent/Consent.vue";

createApp({ components: {
    'scl-header': Header,
    'scl-button': Button,
    'scl-flashmessage': FlashMessage,
    'scl-consent': Consent
}}).mount('#app');


const reset_link = document.querySelector("#session_reset");
if(reset_link !== null) {
    reset_link.addEventListener("click", (e) => {
        e.preventDefault();
        if(confirm('Are you sure you want to reset your session? This will empty your basket and log you out.')) {
            window.location.href = e.target.href;
        }
    })
}

window.addEventListener('DOMContentLoaded', () => {
    let signup_button = document.querySelector("#signup button");
    if(signup_button !== null) {
        signup_button.addEventListener('click', (e) => {
            // maybe try and figure out if klaviyo is actually loaded
            // typeof window.klaviyo === 'function' doesn't work because of the proxy
            if(window.consent.personalization_storage !== 'denied') {
                e.preventDefault();

                window._klOnsite = window._klOnsite || [];
                window._klOnsite.push(['openForm', 'ViUMyV']);
            }
        });
    }
});